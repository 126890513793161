<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ this.title }}</b-card-title>
    </b-card-header>
    
    <b-card-body>
      <div
        v-for="transaction in transactionData"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                animation="cylon"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title font-medium-1">
              {{ transaction.mode }}
            </h6>
            <small>{{ transaction.types }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder font-large-1"
          :class="transaction.deduction ? 'text-danger':'text-success'"
        >
          {{ transaction.value }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      transactionData: [
        {
          mode: 'Tổng số Cán bộ/Giảng viên',
          types: 'CB/GV',
          // avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng Cán bộ/Giảng viên tăng thêm trong năm',
          types: 'CB/GV',
          // avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng Cán bộ đạt bằng Tiến sĩ',
          types: 'CB/GV',
          avatar: 'AwardIcon',
          avatarVariant: 'light-warning',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng Cán bộ đạt bằng Thạc sĩ',
          types: 'CB/GV',
          avatar: 'AwardIcon',
          avatarVariant: 'light-danger',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng Giáo sư',
          types: 'CB/GV',
          avatar: 'AwardIcon',
          avatarVariant: 'light-warning',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng Phó Giáo sư',
          types: 'CB/GV',
          avatar: 'AwardIcon',
          avatarVariant: 'light-danger',
          value: 0,
          deduction: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      countEmployees: 'chart/countEmployees'
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods: {
    ...mapActions({
      getEmployeesOverview: 'chart/getEmployeesOverview'
    }),
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.getEmployeesOverview(this.filter)
        // this.transactionData[0].value = 302
        // this.transactionData[1].value = 13
        // this.transactionData[2].value = 5
        // this.transactionData[3].value = 251
        // this.transactionData[4].value = 2
        // this.transactionData[5].value = 3
        if ( this.countEmployees.length > 0 ) {
          this.transactionData[0].value = this.countEmployees[0].countEmployeesSum
          this.transactionData[1].value = this.countEmployees[0].countEmployeesCurrent
          this.transactionData[2].value = this.countEmployees[0].tsSum
          this.transactionData[3].value = this.countEmployees[0].thsSum
          this.transactionData[4].value = this.countEmployees[0].gsSum
          this.transactionData[5].value = this.countEmployees[0].pgsSum
        }
        else {
          this.transactionData[0].value = 0
          this.transactionData[1].value = 0
          this.transactionData[2].value = 0
          this.transactionData[3].value = 0
          this.transactionData[4].value = 0
          this.transactionData[5].value = 0
        }
      } catch ( e ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>
